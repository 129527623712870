import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Windmill } from "@windmill/react-ui";
import ThemedSuspense from "./components/ThemedSuspense";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Theme from "./theme.js";

ReactDOM.render(
  <Suspense fallback={<ThemedSuspense />}>
    <Windmill usePreferences theme={Theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Windmill>
  </Suspense>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
