import { Link } from "react-router-dom";
import React, { useContext, useState } from "react";
import { useCookies } from "react-cookie";
import { Button, Backdrop, WindmillContext } from "@windmill/react-ui";
import { FaBars, FaMoon, FaSun } from "react-icons/fa";
import { ReactComponent as Logo } from "../images/logo.svg";
import { FaTimes } from "react-icons/fa";

function TopNav() {
  const { mode, toggleMode } = useContext(WindmillContext);
  const [isOpen, setIsOpen] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["session"]);
  function toggleBackdrop() {
    setIsOpen(!isOpen);
  }
  const HandleLogout = () => {
    removeCookie("session");
  };
  return (
    <div className="top-0 z-50">
      {isOpen ? (
        <Button
          iconRight={FaTimes}
          className="w-20 fill-current text-gray-50 absolute top-5 right-2 z-50"
          onClick={toggleBackdrop}
        />
      ) : (
        ""
      )}
      {isOpen && (
        <Backdrop
          onClick={toggleBackdrop}
          className="flex items-center justify-center "
        >
          <div className="flex items-center justify-center  bg-blue-50 dark:bg-gray-900  rounded-lg">
            <ul className=" p-1 rounded-lg">
              <li>
                {" "}
                <Link to="/dashboard">
                  <Button className="m-5 w-48">Dashboard</Button>
                </Link>
              </li>

              <li>
                {" "}
                <Link to="/account">
                  <Button className="m-5 w-48">Account Settings</Button>
                </Link>
              </li>
              <li>
                {" "}
                <Button className="m-5 w-48 " onClick={HandleLogout}>
                  Logout
                </Button>
              </li>
              <li>
                <Button
                  className="m-5 w-48 focus:outline-none focus:shadow-outline-blue"
                  onClick={toggleMode}
                  layout="outline"
                  aria-label="Toggle color mode"
                >
                  {mode === "dark" ? (
                    <FaSun
                      className="w-5 h-5 fill-current text-gray-50 "
                      aria-hidden="true"
                    />
                  ) : (
                    <FaMoon
                      className="w-5 h-5 fill-current text-gray-300"
                      aria-hidden="true"
                    />
                  )}
                </Button>
              </li>
            </ul>
          </div>
        </Backdrop>
      )}
      <nav className="flex items-center justify-between p-2 rounded-b-lg bg-gray-50 dark:bg-gray-800 shadow-lg">
        <Link to="/">
          <Logo className="w-20 h-20" aria-hidden="true" />
        </Link>
        <div className="relative space-x-4 m-4">
          {cookies["session"] == undefined ? (
            <Link to="/login">
              {" "}
              <Button
                aria-label="Like"
                className="bg-blue-700 fill-current text-gray-50 md:w-28"
              >
                Login
              </Button>
            </Link>
          ) : (
            ""
          )}
          <Button
            aria-label="Like"
            className="bg-blue-700 fill-current text-gray-50 w-20"
            onClick={toggleBackdrop}
          >
            <FaBars
              className="w-5 h-5 fill-current text-gray-300"
              aria-hidden="true"
            />
          </Button>
        </div>
      </nav>
    </div>
  );
}

export default TopNav;
