export default function Footer() {
  return (
    <>
      <footer className="relative">
        <div className="container max-w-7xl mx-auto px-4">
          <hr className="my-6 border-blue-700" />
          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center">
              <div className="text-sm text-blue-700 font-medium py-1">
                &copy;2022 SHOPAY - All Rights Reserved
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
