import React from "react";
import { ReactComponent as Loading } from "../images/logo.svg";
import PageTitle from "./PageTitle";
function ThemedSuspense() {
  return (
    <div className="w-full h-screen p-6 text-lg font-medium text-gray-600 dark:text-gray-400 dark:bg-gray-900 flex flex-col items-center justify-center animate-pulse">
      <Loading className="w-40 h-40" aria-hidden="true" />
      <PageTitle>Shopay</PageTitle>
    </div>
  );
}

export default ThemedSuspense;
