import { lazy } from "react";
import { useCookies } from "react-cookie";
import { Route, Routes } from "react-router-dom";
import TopNav from "./components/TopNav";
import Footer from "./components/Footer";
const Upload = lazy(() => import("./pages/admin/Upload"));
const Home = lazy(() => import("./pages/Home"));
const Products = lazy(() => import("./pages/Products"));
const Item = lazy(() => import("./pages/Item"));
const Dashboard = lazy(() => import("./pages/admin/Dashboard"));
const Order = lazy(() => import("./pages/Order"));
const Login = lazy(() => import("./pages/Login"));
const Register = lazy(() => import("./pages/Register"));
const Account = lazy(() => import("./pages/Account"));

function Router() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/store/:storeName" element={<Products />} />
      <Route path="/store/:storeName/item/:pId" element={<Item />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/login/:storeName" element={<Login />} />
      <Route path="/register/:storeName" element={<Register />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/account" element={<Account />} />
      <Route path="/add" element={<Upload />} />
      <Route path="/store/:storeName/:edit" element={<Products />} />
      <Route path="/edit/:storeName/item/:pId" element={<Upload />} />
      <Route path="/order/:storeName/item/:pId" element={<Order />} />
    </Routes>
  );
}

function App() {
  const [cookies, setCookie, removeCookie] = useCookies([
    "session",
    "showEdit",
  ]);
  return (
    <div className="bg-blue-50 dark:bg-gray-900 w-screen overflow-hidden">
      {cookies["session"] != undefined ? <TopNav /> : ""}

      <div className="min-h-screen m-5 md:my-5">
        <Router />
      </div>
      <div className="mt-5" />
      <Footer />
    </div>
  );
}

export default App;
